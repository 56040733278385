import classNames from 'classnames'
import React from 'react'
import style from './Checkbox.module.css'

export enum CheckboxTheme {
  White = 'White',
  Gray = 'Gray',
  Black = 'Black'
}
export interface CheckboxProps {
  isChecked: boolean
  label?: string
  theme?: CheckboxTheme
  onChange: (value: boolean) => void
}

export function Checkbox({
  onChange,
  isChecked,
  label,
  theme = CheckboxTheme.White
}: CheckboxProps) {
  const handleChange = () => onChange(!isChecked)

  return (
    <div className={style.container}>
      <div
        className={classNames(style.root, {
          [style.gray]: theme === CheckboxTheme.Gray,
          [style.white]: theme === CheckboxTheme.White,
          [style.black]: theme === CheckboxTheme.Black,
          [style.relative]: !!label
        })}
        onClick={handleChange}
      >
        {isChecked && <div className={style.box} />}
      </div>
      {label && (
        <span className={style.label} onClick={handleChange}>
          {label}
        </span>
      )}
    </div>
  )
}
